import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// users
import user from "../../../assets/images/users/user.jpg"

const ProfileMenu = props => {
  const store = useSelector(state => state.Profile.store)

  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  const [username, setusername] = useState("Admin")

  const [image, setImage] = useState(user)

  useEffect(() => {
    setusername(store?.name)
    if (store?.image) {
      if (store.image !== "") {
        setImage(store?.image)
      }
    }
  }, [store])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={image}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" href="/profili">
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            Profili
          </DropdownItem>

          <DropdownItem tag="a" href="/ndihme">
            {" "}
            <i className="bx bx-help-circle font-size-16 align-middle me-1" />
            Ndihme
          </DropdownItem>
          <DropdownItem tag="a" href="/reset">
            {" "}
            <i className="bx bx-help-circle font-size-16 align-middle me-1" />
            Ndrysho fjalkalimin
          </DropdownItem>

          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>Dilni</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(ProfileMenu)
