import React from "react"
import { useDrop, useDrag } from "react-dnd"
import { Button } from "reactstrap"

function BrochureImageCard({
  file,
  id,
  index,
  moveImage,
  selectedFiles,
  setSelectedFiles,
}) {
  const ref = React.useRef(null)
  const [, drop] = useDrop({
    accept: "image",
    hover: (item, monitor) => {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) {
        return
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      moveImage(dragIndex, hoverIndex)
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: "image",
    item: () => {
      return { id, index }
    },
    collect: monitor => {
      return {
        isDragging: monitor.isDragging(),
      }
    },
  })

  drag(drop(ref))

  return (
    <div ref={ref} className="border rounded">
      <div className="d-flex flex-wrap gap-2 p-2">
        <div className="flex-shrink-0 me-3">
          <div className="avatar-xl bg-light rounded p-2">
            <img
              data-dz-thumbnail=""
              style={{
                overflow: "hidden",
                maxHeight: "7rem",
              }}
              className="img-fluid rounded d-block"
              src={file.preview}
              alt={file.name}
            />
          </div>
        </div>
        <div className="flex-grow-1">
          <div className="pt-1">
            <h5 className="fs-md mb-1" data-dz-name>
              {index + 1}
            </h5>
            <strong className="error text-danger" data-dz-errormessage></strong>
          </div>
        </div>
        <div className="flex-shrink-0 ms-3">
          <Button
            variant="danger"
            size="sm"
            onClick={() => {
              const newss = [...selectedFiles]
              newss.splice(index, 1)
              setSelectedFiles(newss)
            }}
          >
            Delete
          </Button>
        </div>
      </div>
    </div>
  )
}

export default BrochureImageCard
