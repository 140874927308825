export const EDIT_PROFILE = "EDIT_PROFILE"
export const PROFILE_SUCCESS = "PROFILE_SUCCESS"
export const PROFILE_ERROR = "PROFILE_ERROR"
export const RESET_PROFILE_FLAG = "RESET_PROFILE_FLAG"

export const GET_STORE = "GET_STORE"
export const GET_STORE_SUCCESS = "GET_STORE_SUCCESS"
export const GET_STORE_FAIL = "GET_STORE_FAIL"

export const ADD_STORE = "ADD_STORE"
export const ADD_STORE_SUCCESS = "ADD_STORE_SUCCESS"
export const ADD_STORE_FAIL = "ADD_STORE_FAIL"

export const UPDATE_STORE = "UPDATE_STORE"
export const UPDATE_STORE_SUCCESS = "UPDATE_STORE_SUCCESS"
export const UPDATE_STORE_FAIL = "UPDATE_STORE_FAIL"

export const GET_CATEGORIES = "GET_CATEGORIES"
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS"
export const GET_CATEGORIES_FAIL = "GET_CATEGORIES_FAIL"
