import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_BROCHURES,
  ADD_BROCHURE,
  DELETE_BROCHURE,
  UPDATE_BROCHURE,
} from "./actionTypes"
import {
  getBrochuresSuccess,
  getBrochuresFail,
  addBrochureFail,
  addBrochureSuccess,
  deleteBrochureSuccess,
  deleteBrochureFail,
  updateBrochureSuccess,
  updateBrochureFail,
} from "./actions"

import { toast } from "react-toastify"
import { getFirebaseBackend } from "helpers/firebase_helper"

const fireBaseBackend = getFirebaseBackend()

function* getBrochures() {
  try {
    const authUser = JSON.parse(localStorage.getItem("authUser"))

    const response = yield call(fireBaseBackend.getBrochuresAPI, authUser.uid)
    yield put(getBrochuresSuccess(response))
  } catch (error) {
    yield put(getBrochuresFail(error))
  }
}

function* onUpdateBrochure({ payload: { brochure, clearData } }) {
  try {
    const response = yield call(
      fireBaseBackend.updateBrochure,
      brochure.id,
      brochure
    )
    yield put(updateBrochureSuccess(response))
    clearData()
    toast.success("Fletushka u perditsua me sukses", { autoClose: 2000 })
  } catch (error) {
    yield put(updateBrochureFail(error))
    toast.error("Gabim gjate perditsimit te fletushkes", { autoClose: 2000 })
  }
}

function* onDeleteBrochure({ payload: brochureId }) {
  try {
    const response = yield call(fireBaseBackend.deleteBrochureAPI, brochureId)
    yield put(deleteBrochureSuccess(brochureId))
    toast.success("Fletushka u fshi me sukses!", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteBrochureFail(error))
    toast.error("Fletushka u fshi me sukses!", { autoClose: 2000 })
  }
}

function* addBrochure({ payload: { data, clearData } }) {
  try {
    const response = yield call(fireBaseBackend.addBrochureAPI, data)
    yield put(addBrochureSuccess(response))
    clearData()
    toast.success("Fletushka u shtua me sukses", { autoClose: 2000 })
  } catch (error) {
    yield put(addBrochureFail(error))
    toast.error("Gabim gjate shtimit te feltushkes", { autoClose: 2000 })
  }
}

function* brochuresSaga() {
  yield takeEvery(GET_BROCHURES, getBrochures)
  yield takeEvery(ADD_BROCHURE, addBrochure)
  yield takeEvery(UPDATE_BROCHURE, onUpdateBrochure)
  yield takeEvery(DELETE_BROCHURE, onDeleteBrochure)
}

export default brochuresSaga
