import React, { useMemo, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Label,
  Row,
} from "reactstrap"
import Select from "react-select"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import * as Yup from "yup"
import { useFormik } from "formik"
import { useDispatch, useSelector } from "react-redux"
import { addStore } from "store/actions"
import { KsCities } from "helpers/constans"
import { getFirebaseBackend } from "helpers/firebase_helper"
import { toast } from "react-toastify"

const FillStoreData = () => {
  const fireBaseBackend = getFirebaseBackend()

  const dispatch = useDispatch()
  //meta title
  document.title = "Te dhenat"
  const categories = useSelector(state => state.Profile.categories)

  const [selectedCategory, setSelectedCategory] = useState()
  const [selectedImage, setSelectedImage] = useState(null)
  const [selectedCity, setSelectedCity] = useState(null)

  const handleImageChange = async event => {
    event.preventDefault()
    const name = validation.values.name
    if (name.length === 0) {
      toast.error("Vendosni emrin se pari!")
    } else {
      let file = event.target.files[0]
      const imageURL = await fireBaseBackend.uploadStoreImage(file, name)
      setSelectedImage(imageURL)
      validation.setFieldValue("image", imageURL)
    }
  }

  // validation
  const validation = useFormik({
    initialValues: {
      name: "",
      categoryId: "",
      image: "",
      city: "",
      websiteURL: "",
      socialMediaURL: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Ju lutem vendosni emrin e biznesit"),
      categoryId: Yup.string().required("Ju lutem selektoni kategorin"),
      image: Yup.string().required("Ju lutem selektoni nje llogo"),
      city: Yup.array().required("Ju lutem selektoni qytetin"),
      websiteURL: Yup.string()
        .url("Linku duhet te jete valid!")
        .transform(currentValue => {
          const doesNotStartWithHttp =
            currentValue &&
            !(
              currentValue.startsWith("http://") ||
              currentValue.startsWith("https://")
            )

          if (doesNotStartWithHttp) {
            return `http://${currentValue}`
          }
          return currentValue
        }),
      socialMediaURL: Yup.string()
        .url("Linku duhet te jete valid!")
        .transform(currentValue => {
          const doesNotStartWithHttp =
            currentValue &&
            !(
              currentValue.startsWith("http://") ||
              currentValue.startsWith("https://")
            )

          if (doesNotStartWithHttp) {
            return `http://${currentValue}`
          }
          return currentValue
        }),
    }),
    onSubmit: values => {
      dispatch(addStore(values))
    },
  })

  function handleSelectGroup(selectedCategory) {
    setSelectedCategory(selectedCategory)
    validation.setFieldValue("categoryId", selectedCategory.value)
  }

  const categoriesOptions = useMemo(
    () => categories.map(item => ({ label: item.name, value: item.id })),
    [categories]
  )

  function handleSelectCity(selectedCityVal) {
    setSelectedCity(selectedCityVal)
    const all = selectedCityVal?.find(item => item.label === KsCities[0])
    if (all) {
      validation.setFieldValue("city", ["all"])
    } else {
      validation.setFieldValue(
        "city",
        selectedCityVal.map(item => item.value)
      )
    }
  }

  const citiesOptions = () => {
    return KsCities.map(city => ({ label: city, value: city }))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Te dhenat"
            breadcrumbItem="Mbushni te dhenat e juaja"
          />
          <Form
            id="createproject-form"
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <div className="mb-3">
                      <Label htmlFor="name-input">Emri i biznesit</Label>
                      <Input
                        id="name"
                        name="name"
                        type="text"
                        placeholder="Shkruani emrin e biznesit..."
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name || ""}
                      />
                      {validation.touched.name && validation.errors.name ? (
                        <FormFeedback type="invalid" className="d-block">
                          {validation.errors.name}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3 position-relative">
                      <Label htmlFor="task-assign-input">
                        Selektoni qytetin
                      </Label>

                      <Select
                        value={selectedCity}
                        onChange={handleSelectCity}
                        options={citiesOptions()}
                        isMulti={true}
                        className="select2-selection"
                      />

                      {validation.touched.city && validation.errors.city ? (
                        <FormFeedback type="invalid" className="d-block">
                          {validation.errors.city}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3 position-relative">
                      <Label htmlFor="task-assign-input">
                        Kategoria e biznesit
                      </Label>

                      <Select
                        value={selectedCategory}
                        onChange={handleSelectGroup}
                        options={categoriesOptions}
                        className="select2-selection"
                      />

                      {validation.touched.categoryId &&
                      validation.errors.categoryId ? (
                        <FormFeedback type="invalid" className="d-block">
                          {validation.errors.categoryId}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label htmlFor="name-input">
                        Linku i website-it tuaj (Opsional)
                      </Label>
                      <Input
                        id="websiteURL"
                        name="websiteURL"
                        type="text"
                        placeholder="Shkruani linkun e website-it..."
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.websiteURL || ""}
                      />
                      {validation.touched.websiteURL &&
                      validation.errors.websiteURL ? (
                        <FormFeedback type="invalid" className="d-block">
                          {validation.errors.websiteURL}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label htmlFor="name-input">
                        Linku i mediave sociale psh. ne Facebook (Opsional)
                      </Label>
                      <Input
                        id="socialMediaURL"
                        name="socialMediaURL"
                        type="text"
                        placeholder="Shkruani linkun e medias sociale..."
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.socialMediaURL || ""}
                      />
                      {validation.touched.socialMediaURL &&
                      validation.errors.socialMediaURL ? (
                        <FormFeedback type="invalid" className="d-block">
                          {validation.errors.socialMediaURL}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Llogoja e biznesit</Label>

                      <div className="text-center">
                        <div className="position-relative d-inline-block">
                          <div className="position-absolute bottom-0 end-0">
                            <Label
                              htmlFor="project-image-input"
                              className="mb-0"
                              id="imageInput"
                            >
                              <div className="avatar-xs">
                                <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer shadow font-size-16">
                                  <i className="bx bxs-image-alt"></i>
                                </div>
                              </div>
                            </Label>
                            <UncontrolledTooltip
                              placement="right"
                              target="imageInput"
                            >
                              Selekto llogon e biznesit
                            </UncontrolledTooltip>
                            <input
                              className="form-control d-none"
                              id="project-image-input"
                              type="file"
                              accept="image/png, image/gif, image/jpeg"
                              onChange={handleImageChange}
                            />
                          </div>
                          <div className="avatar-lg">
                            <div className="avatar-title bg-light rounded-circle">
                              <img
                                src={selectedImage || ""}
                                id="projectlogo-img"
                                alt=""
                                className="avatar-md h-auto rounded-circle"
                              />
                            </div>
                          </div>
                        </div>
                        {validation.touched.image && validation.errors.image ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.image}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col lg={12}>
                <div className="text-end mb-4">
                  <Button type="submit" color="primary">
                    Ruaj te dhenat
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FillStoreData
