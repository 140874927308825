import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import {
  ADD_STORE,
  GET_CATEGORIES,
  GET_STORE,
  UPDATE_STORE,
} from "./actionTypes"
import {
  profileSuccess,
  profileError,
  getStoreSuccess,
  getStoreFail,
  addStoreSuccess,
  getCategoriesSuccess,
  getCategoriesFail,
} from "./actions"

import { getStore as getStoreAction } from "store/actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import { Timestamp } from "firebase/firestore"
import { toast } from "react-toastify"
const fireBaseBackend = getFirebaseBackend()

function* getCategories() {
  try {
    const response = yield call(fireBaseBackend.getCategoriesAPI)
    yield put(getCategoriesSuccess(response))
  } catch (error) {
    yield put(getCategoriesFail(error))
  }
}
function* getStore() {
  try {
    const authUser = JSON.parse(localStorage.getItem("authUser"))
    const response = yield call(fireBaseBackend.getStoreAPI, authUser.uid)
    yield put(getStoreSuccess(response))
  } catch (error) {
    yield put(getStoreFail(error))
  }
}

function* addStore({ payload }) {
  try {
    const authUser = JSON.parse(localStorage.getItem("authUser"))

    yield call(fireBaseBackend.addStoreAPI, authUser.uid, {
      ...payload,
      email: authUser.email,
      country: "ks",
      createdAt: Timestamp.now().seconds,
    })

    yield put(
      getStoreSuccess({
        ...payload,
        email: authUser.email,
        country: "ks",
        createdAt: Timestamp.now().seconds,
        id: authUser.uid,
      })
    )
  } catch (error) {
    yield put(addStoreSuccess(error))
  }
}

function* updateStore({ payload: data }) {
  try {
    const response = yield call(fireBaseBackend.updateStoreAPI, data.id, data)
    yield put(profileSuccess(response))
    yield put(getStoreAction())
    toast.success("Profili u perditsua me sukses", { autoClose: 2000 })
  } catch (error) {
    toast.error("Gabim gjate perditsimit te profilit", { autoClose: 2000 })
    yield put(profileError(error))
  }
}

export function* watchProfile() {
  yield takeEvery(UPDATE_STORE, updateStore)
  yield takeEvery(GET_STORE, getStore)
  yield takeEvery(ADD_STORE, addStore)
  yield takeEvery(GET_CATEGORIES, getCategories)
}

function* ProfileSaga() {
  yield all([fork(watchProfile)])
}

export default ProfileSaga
