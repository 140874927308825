import React from "react"
import { Navigate } from "react-router-dom"

// //Projects
import BrochuresList from "../pages/Brochures/brochures-list"
import BrochuresCreate from "../pages/Brochures/brochure-create"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

import UserProfile from "pages/Authentication/user-profile"
import Dashboard from "pages/Brochures/dashboard"
import LocationsList from "pages/Locations/locations-list"
import Help from "pages/Authentication/help"
import Reset from "pages/Authentication/reset"

const authProtectedRoutes = [
  { path: "/fletushkat-aktive", component: <Dashboard /> },

  { path: "/lokacionet", component: <LocationsList /> },
  //Projects
  { path: "/shto-fletushka", component: <BrochuresCreate /> },
  //Profile
  { path: "/profili", component: <UserProfile /> },

  { path: "/ndihme", component: <Help /> },
  { path: "/reset", component: <Reset /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/fletushkat-aktive" />,
  },
]

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
]

export { authProtectedRoutes, publicRoutes }
