import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  RESET_PROFILE_FLAG,
  GET_STORE,
  GET_STORE_SUCCESS,
  GET_STORE_FAIL,
  ADD_STORE,
  ADD_STORE_SUCCESS,
  ADD_STORE_FAIL,
  GET_CATEGORIES,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAIL,
  UPDATE_STORE,
  UPDATE_STORE_SUCCESS,
  UPDATE_STORE_FAIL,
} from "./actionTypes"

export const editProfile = user => {
  return {
    type: EDIT_PROFILE,
    payload: { user },
  }
}

export const profileSuccess = msg => {
  return {
    type: PROFILE_SUCCESS,
    payload: msg,
  }
}

export const profileError = error => {
  return {
    type: PROFILE_ERROR,
    payload: error,
  }
}

export const getCategories = () => {
  return {
    type: GET_CATEGORIES,
  }
}

export const getCategoriesSuccess = categories => {
  return {
    type: GET_CATEGORIES_SUCCESS,
    payload: categories,
  }
}

export const getCategoriesFail = error => {
  return {
    type: GET_CATEGORIES_FAIL,
    payload: error,
  }
}
export const getStore = () => {
  return {
    type: GET_STORE,
  }
}

export const getStoreSuccess = store => {
  return {
    type: GET_STORE_SUCCESS,
    payload: store,
  }
}

export const getStoreFail = error => {
  return {
    type: GET_STORE_FAIL,
    payload: error,
  }
}

export const updateStore = data => {
  return {
    type: UPDATE_STORE,
    payload: data,
  }
}

export const updateStoreSuccess = store => {
  return {
    type: UPDATE_STORE_SUCCESS,
    payload: store,
  }
}

export const updateStoreFail = error => {
  return {
    type: UPDATE_STORE_FAIL,
    payload: error,
  }
}
export const addStore = (data, getStore) => {
  return {
    type: ADD_STORE,
    payload: data,
    getStore,
  }
}

export const addStoreSuccess = store => {
  return {
    type: ADD_STORE_SUCCESS,
    payload: store,
  }
}

export const addStoreFail = error => {
  return {
    type: ADD_STORE_FAIL,
    payload: error,
  }
}

export const resetProfileFlag = error => {
  return {
    type: RESET_PROFILE_FLAG,
  }
}
