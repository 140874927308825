export const KsCities = [
  "Gjithe Kosoven",
  "Deçan",
  "Dragash",
  "Drenas",
  "Ferizaj",
  "Fushë Kosovë",
  "Gjakovë",
  "Gjilan",
  "Istog",
  "Kaçanik",
  "Kamenicë",
  "Klinë",
  "Lipjan",
  "Malishevë",
  "Mitrovicë",
  "Obiliq",
  "Pejë",
  "Podujevë",
  "Prishtinë",
  "Prizren",
  "Rahovec",
  "Skenderaj",
  "Suharekë",
  "Shtërpc",
  "Shtime",
  "Viti",
  "Vushtrri",
]

export const cityInfos = [
  { city: "Deçan", center: [42.54, 20.2861] },
  { city: "Dragash", center: [42.0644, 20.6531] },
  { city: "Drenas", center: [42.6248, 20.933] },
  { city: "Ferizaj", center: [42.3703, 21.1553] },
  { city: "Fushë Kosovë", center: [42.65, 21.0969] },
  { city: "Gjakovë", center: [42.39, 20.43] }, // Verified coordinates&#8203;:contentReference[oaicite:0]{index=0}
  { city: "Gjilan", center: [42.4602, 21.4669] },
  { city: "Istog", center: [42.78, 20.4878] },
  { city: "Kaçanik", center: [42.23, 21.2597] },
  { city: "Kamenicë", center: [42.5803, 21.58] },
  { city: "Klinë", center: [42.6217, 20.5794] },
  { city: "Lipjan", center: [42.5356, 21.1347] },
  { city: "Malishevë", center: [42.4817, 20.745] },
  { city: "Mitrovicë", center: [42.9167, 20.8833] }, // Verified coordinates&#8203;:contentReference[oaicite:1]{index=1}
  { city: "Obiliq", center: [42.6825, 21.0686] },
  { city: "Pejë", center: [42.6591, 20.2883] }, // Verified coordinates&#8203;:contentReference[oaicite:2]{index=2}
  { city: "Podujevë", center: [42.9103, 21.1944] },
  { city: "Prishtinë", center: [42.6629, 21.1655] },
  { city: "Prizren", center: [42.2139, 20.7397] },
  { city: "Rahovec", center: [42.4022, 20.6531] },
  { city: "Skenderaj", center: [42.7333, 20.8] },
  { city: "Suharekë", center: [42.3647, 20.8292] },
  { city: "Shtërpc", center: [42.2394, 21.045] },
  { city: "Shtime", center: [42.4331, 21.0408] },
  { city: "Viti", center: [42.32, 21.3583] },
  { city: "Vushtrri", center: [42.8233, 20.9672] },
]
