import React, { useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { Col, Container, Row, Card, CardBody, Button } from "reactstrap"

//Import Component
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"
// FlatPickr
import "flatpickr/dist/themes/material_blue.css"

import {
  getBrochures as onGetBrochures,
  addBrochure as onAddBrochure,
  // updatebrochure as onUpdatebrochure,
  deleteBrochure as onDeleteBrochure,
  setBrochureToEdit,
} from "../../store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import Spinners from "components/Common/Spinner"
import TableContainer from "components/Common/TableContainer"
import dayjs from "dayjs"

const BrochuresList = () => {
  //meta title
  document.title = "Fletushkat Aktive"

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [brochure, setBrochure] = useState()

  const brochuresBrochureProperties = createSelector(
    state => state.Brochures,
    Brochures => ({
      brochures: Brochures.brochures,
      loading: Brochures.loading,
    })
  )

  const { brochures, loading } = useSelector(brochuresBrochureProperties)
  const [isLoading, setLoading] = useState(loading)
  const [brochureData, setBrochureData] = useState()

  const handleBrochureClick = arg => {
    dispatch(setBrochureToEdit(arg))
    navigate("/shto-fletushka")
  }

  //delete order
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = brochure => {
    setBrochure(brochure)
    setDeleteModal(true)
  }

  const handleDeleteOrder = () => {
    if (brochure && brochure.id) {
      dispatch(onDeleteBrochure(brochure.id))
    }
    setDeleteModal(false)
  }

  useEffect(() => {
    dispatch(onGetBrochures())
    dispatch(setBrochureToEdit(null))
  }, [dispatch])

  useEffect(() => {
    setBrochureData(brochures)
  }, [brochures])

  const columns = useMemo(
    () => [
      {
        header: "#",
        id: "#",
        cell: cellProps => (
          <div className="avatar-sm bg-light rounded p-2">
            <img
              src={cellProps.row.original.images[0]}
              alt=""
              className="img-fluid"
            />
          </div>
        ),
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Titulli",
        accessorKey: "title",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Qyteti",
        accessorKey: "city",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Data aktive",
        accessorKey: "dueDate",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cellProps => {
          return (
            <>
              <h5 className="text-truncate font-size-14">
                {`${dayjs
                  .unix(cellProps.row.original.startDate)
                  .format("DD/MM/YYYY")} - ${dayjs
                  .unix(cellProps.row.original.endDate)
                  .format("DD/MM/YYYY")}`}
              </h5>
            </>
          )
        },
      },

      {
        accessorKey: "edito",
        header: "Edito",
        enableColumnFilter: false,

        cell: cellProps => {
          return (
            <div>
              {cellProps.getValue()}
              <Button
                onClick={e => {
                  e.stopPropagation() // Prevent row click from triggering
                  handleBrochureClick(cellProps.row.original)
                }}
              >
                <span>Edito</span>
              </Button>
            </div>
          )
        },
      },
      {
        accessorKey: "fshije",
        header: "Fshije",
        enableColumnFilter: false,

        cell: cellProps => {
          return (
            <div>
              {cellProps.getValue()}
              <Button
                style={{ backgroundColor: "red", borderWidth: 0 }}
                onClick={e => {
                  e.stopPropagation() // Prevent row click from triggering
                  onClickDelete(cellProps.row.original)
                }}
              >
                <span>Fshij</span>
              </Button>
            </div>
          )
        },
      },
    ],
    []
  )

  const handleBrochureClicks = () => {
    navigate("/shto-fletushka")
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
        text={"A jeni i sigurt qe doni te fshini kete fletushke?"}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Fletushkat aktive"
            breadcrumbItem="Lista e fletushkave aktive"
          />

          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      columns={columns}
                      data={brochureData}
                      isGlobalFilter={true}
                      isAddButton={true}
                      isPagination={true}
                      isCustomPageSize={true}
                      handleUserClick={handleBrochureClicks}
                      SearchPlaceholder="Kerko fletushken..."
                      buttonClass="btn btn-rounded"
                      buttonName="Shto fletushkë te re"
                      tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                      theadClass="table-light"
                      paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                      pagination="pagination"
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(BrochuresList)
