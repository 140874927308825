import React from "react"
import BrochuresList from "./brochures-list"
import FillStoreData from "pages/Authentication/fill-store-data"
import { useSelector } from "react-redux"
import { Spinner } from "reactstrap"

function Dashboard() {
  const store = useSelector(state => state.Profile.store)
  const loadingStore = useSelector(state => state.Profile.loadingStore)

  return loadingStore ? (
    <Spinner color="primary" className="position-absolute top-50 start-50" />
  ) : store ? (
    <BrochuresList />
  ) : (
    <FillStoreData />
  )
}

export default Dashboard
