import React from "react"
import { Container, Label } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const Help = () => {
  document.title = "Ndihme per perdorim"

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Ndihme" breadcrumbItem="Video e perdorimit" />
          <Label
            style={{
              marginBottom: 20,
              fontWeight: "bold",
            }}
          >
            Per te pare videon e perdorimit klikoni kete{" "}
            <a href="https://drive.google.com/file/d/1Ivpi7wB6cUScU-KEfXbmTYjzzr93XdWz/view">
              link
            </a>
            .
          </Label>
          <p>
            <Label>
              Per qfardo problemi qe mund te keni apo nese keni ndonje sygjerim
              ju lutem na kontaktoni ne emailin: fletushka12@gmail.com
            </Label>
          </p>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Help
