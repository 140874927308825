import {
  GET_BROCHURES_FAIL,
  GET_BROCHURES_SUCCESS,
  ADD_BROCHURE_SUCCESS,
  ADD_BROCHURE_FAIL,
  UPDATE_BROCHURE_SUCCESS,
  UPDATE_BROCHURE_FAIL,
  DELETE_BROCHURE_SUCCESS,
  DELETE_BROCHURE_FAIL,
  SET_BROCHURE_TO_EDIT,
} from "./actionTypes"

const INIT_STATE = {
  brochures: [],
  error: {},
  loading: true,
  brochureToEdit: null,
}

const brochures = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BROCHURES_SUCCESS:
      return {
        ...state,
        brochures: action.payload,
        loading: true,
      }

    case GET_BROCHURES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_BROCHURE_SUCCESS:
      return {
        ...state,
        brochures: [action.payload, ...state.brochures],
      }

    case ADD_BROCHURE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_BROCHURE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_BROCHURE_SUCCESS:
      return {
        ...state,
        brochures: state.brochures.filter(
          brochure => brochure.id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_BROCHURE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case SET_BROCHURE_TO_EDIT:
      return {
        ...state,
        brochureToEdit: action.payload,
      }

    default:
      return state
  }
}

export default brochures
