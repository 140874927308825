import {
  GET_BROCHURES,
  GET_BROCHURES_FAIL,
  GET_BROCHURES_SUCCESS,
  UPDATE_BROCHURE,
  UPDATE_BROCHURE_SUCCESS,
  UPDATE_BROCHURE_FAIL,
  DELETE_BROCHURE,
  DELETE_BROCHURE_SUCCESS,
  DELETE_BROCHURE_FAIL,
  ADD_BROCHURE,
  ADD_BROCHURE_SUCCESS,
  ADD_BROCHURE_FAIL,
  SET_BROCHURE_TO_EDIT,
} from "./actionTypes"

export const getBrochures = () => ({
  type: GET_BROCHURES,
})

export const getBrochuresSuccess = brochures => ({
  type: GET_BROCHURES_SUCCESS,
  payload: brochures,
})

export const getBrochuresFail = error => ({
  type: GET_BROCHURES_FAIL,
  payload: error,
})

export const addBrochure = (data, clearData) => ({
  type: ADD_BROCHURE,
  payload: { data, clearData },
})

export const addBrochureSuccess = brochure => ({
  type: ADD_BROCHURE_SUCCESS,
  payload: brochure,
})

export const addBrochureFail = error => ({
  type: ADD_BROCHURE_FAIL,
  payload: error,
})

export const updateBrochure = (brochure, clearData) => ({
  type: UPDATE_BROCHURE,
  payload: { brochure, clearData },
})

export const updateBrochureSuccess = brochure => ({
  type: UPDATE_BROCHURE_SUCCESS,
  payload: brochure,
})

export const updateBrochureFail = error => ({
  type: UPDATE_BROCHURE_FAIL,
  payload: error,
})

export const deleteBrochure = brochureId => ({
  type: DELETE_BROCHURE,
  payload: brochureId,
})

export const deleteBrochureSuccess = brochureId => ({
  type: DELETE_BROCHURE_SUCCESS,
  payload: brochureId,
})

export const deleteBrochureFail = error => ({
  type: DELETE_BROCHURE_FAIL,
  payload: error,
})

export const setBrochureToEdit = brochure => ({
  type: SET_BROCHURE_TO_EDIT,
  payload: brochure,
})
