import React, { useEffect, useMemo, useState } from "react"
import Dropzone from "react-dropzone"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  InputGroup,
  Label,
  Row,
  Spinner,
} from "reactstrap"
import Flatpickr from "react-flatpickr"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import Select from "react-select"

import * as Yup from "yup"
import { useFormik } from "formik"
import { useDispatch, useSelector } from "react-redux"
import dayjs from "dayjs"
import { addBrochure, updateBrochure } from "store/actions"
import withRouter from "components/Common/withRouter"
import { KsCities } from "helpers/constans"
import { useDrag } from "react-dnd"
import BrochureImageCard from "components/Common/BrouchureImageCard"
import { getFirebaseBackend } from "helpers/firebase_helper"
import { toast } from "react-toastify"

const BrochuresCreate = props => {
  //meta title
  const fireBaseBackend = getFirebaseBackend()

  document.title = "Shto Fletushke te re"
  const dispatch = useDispatch()
  const store = useSelector(state => state.Profile.store)
  const brochureToEdit = useSelector(state => state.Brochures.brochureToEdit)
  const [loadingImages, setLoadingImages] = useState(false)

  const [selectedFiles, setSelectedFiles] = useState([])
  const [selectedCity, setSelectedCity] = useState(null)
  const [selectedLocations, setSelectedLocations] = useState(null)

  const [{ isDragging }, drag] = useDrag({
    type: "image",
    item: () => {
      return { id, index }
    },
    collect: monitor => {
      return {
        isDragging: monitor.isDragging(),
      }
    },
  })

  useEffect(() => {
    if (brochureToEdit) {
      try {
        setSelectedLocations(
          brochureToEdit?.locations?.map(item => ({
            label: `${item.city} : ${item.name}`,
            value: `${item.city} : ${item.name}`,
          }))
        )
        validation.setFieldValue("location", brochureToEdit.locations)
      } catch (error) {
        console.log(error)
      }
      if (brochureToEdit.city.includes("all")) {
        setSelectedCity([{ label: KsCities[0], value: KsCities[0] }])
        validation.setFieldValue("city", ["all"])
      } else {
        setSelectedCity(
          brochureToEdit.city.map(item => ({ value: item, label: item }))
        )

        validation.setFieldValue("city", brochureToEdit.city)
      }
      setSelectedFiles(
        brochureToEdit.images.map(item => ({
          preview: item,
          id: Math.random(),
        }))
      )
    }
  }, [brochureToEdit])

  useEffect(() => {
    validation.setFieldValue(
      "images",
      selectedFiles.map(item => item.preview)
    )
  }, [selectedFiles])

  const handleAcceptedFiles = async files => {
    const startDate = validation.values.startDate
    const endDate = validation.values.endDate
    if (startDate !== "" && endDate !== "") {
      setLoadingImages(true)
      const imageURLs = await fireBaseBackend.uploadMultipleImages(
        files,
        endDate
      )
      const newss = imageURLs?.map((url, index) => ({
        preview: url,
        id: Math.random(),
      }))
      setSelectedFiles([...selectedFiles, ...newss])
      setLoadingImages(false)
    } else {
      toast.error("Zgjidhni datat aktive se pari!")
    }
  }

  function handleSelectCity(selectedCityVal) {
    setSelectedCity(selectedCityVal)
    const all = selectedCityVal?.find(item => item.label === KsCities[0])
    if (all) {
      validation.setFieldValue("city", ["all"])
    } else {
      validation.setFieldValue(
        "city",
        selectedCityVal.map(item => item.value)
      )
    }
  }

  function handleSelectLocation(selectedLocationVal) {
    setSelectedLocations(selectedLocationVal)
    validation.setFieldValue(
      "location",
      selectedLocationVal.map(item => item?.value)
    )
  }

  function handleStartDateSelect(date) {
    validation.setFieldValue("startDate", dayjs(date).format("YYYY-MM-DD"))
  }
  function handleEndDateSelect(date) {
    validation.setFieldValue("endDate", dayjs(date).format("YYYY-MM-DD"))
  }

  const clearData = () => {
    if (!brochureToEdit) {
      setSelectedCity(null)
      setSelectedFiles([])
      validation.resetForm()
    }
  }
  // validation
  const validation = useFormik({
    initialValues: {
      title: brochureToEdit ? brochureToEdit.title : "",
      startDate: brochureToEdit
        ? dayjs.unix(brochureToEdit.startDate).format("YYYY-MM-DD")
        : "",
      endDate: brochureToEdit
        ? dayjs.unix(brochureToEdit.endDate).format("YYYY-MM-DD")
        : "",
      images: brochureToEdit ? brochureToEdit.images : [],
      city: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Ju lutem vendosni nje titull"),
      city: Yup.array().required("Ju lutem selektoni qytetin"),
      // location: Yup.array().required("Ju lutem selektoni lokacionin"),
      startDate: Yup.string().required(
        "Ju lutem selektoni daten kur fillon oferta"
      ),
      images: Yup.array()
        .min(1, "Ju lutem selektoni foto!")
        .required("Ju lutem selektoni foto!"),
      endDate: Yup.string().required(
        "Ju lutem selektoni daten kur mbaron oferta"
      ),
    }),
    onSubmit: values => {
      let locationsArr = []
      const filteredArr = store?.locations?.filter(item =>
        selectedLocations?.find(
          el =>
            el?.label?.split(":")[1].trim() === item?.name &&
            el?.label?.split(":")[0].trim() === item?.city
        )
      )

      if (filteredArr) {
        locationsArr = filteredArr
      }

      brochureToEdit
        ? dispatch(
            updateBrochure(
              {
                ...values,
                updatedAt: dayjs().unix(),
                id: brochureToEdit.id,
                startDate: dayjs(values.startDate).unix(),
                endDate: dayjs(values.endDate).unix(),
                locations: locationsArr,
              },
              clearData
            )
          )
        : dispatch(
            addBrochure(
              {
                ...values,
                createdAt: dayjs().unix(),
                storeId: store.id,
                storeName: store.name,
                storeImage: store.image,
                categoryId: store.categoryId,
                startDate: dayjs(values.startDate).unix(),
                endDate: dayjs(values.endDate).unix(),
                locations: locationsArr,
                websiteURL: store?.websiteURL ?? "",
                socialMediaURL: store?.socialMediaURL ?? "",
              },
              clearData
            )
          )
    },
  })

  const citiesOptions = useMemo(() => {
    if (store?.country === "ks") {
      if (store.city.includes("all")) {
        return KsCities.map(city => ({ label: city, value: city }))
      } else {
        return store.city.map(city => ({ label: city, value: city }))
      }
    } else {
      return []
    }
  }, [store])

  const locationOptions = useMemo(() => {
    if (store?.country === "ks") {
      const formattedArray = selectedCity?.map(item => {
        const arrrr = store?.locations
          ?.filter(el => el?.city === item?.label)
          .map(el => ({
            label: `${el?.city} : ${el?.name}`,
            value: `${el?.city} : ${el?.name}`,
          }))
        return {
          label: item.label,
          options: arrrr,
        }
      })
      return formattedArray
    } else {
      return []
    }
  }, [store, selectedCity, brochureToEdit, selectedLocations])

  const moveImage = React.useCallback((dragIndex, hoverIndex) => {
    setSelectedFiles(prevCards => {
      const clonedCards = [...prevCards]
      const removedItem = clonedCards.splice(dragIndex, 1)[0]
      clonedCards.splice(hoverIndex, 0, removedItem)
      return clonedCards
    })
  }, [])

  const handleLocationDropDownShow = data => {
    let shouldShow = []
    data?.map(item => {
      if (Array.isArray(item?.options) && item?.options?.length > 0) {
        shouldShow.push(item)
        return
      }
    })

    return shouldShow?.length > 0
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Fletushka"
            breadcrumbItem={brochureToEdit ? "Edito fletushken" : "Shto te re"}
          />
          <Form
            id="createproject-form"
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <input
                      type="hidden"
                      className="form-control"
                      id="formAction"
                      name="formAction"
                      defaultValue="add"
                    />
                    <input
                      type="hidden"
                      className="form-control"
                      id="project-id-input"
                    />
                    <div className="mb-3">
                      <Label htmlFor="title-input">Titulli</Label>
                      <Input
                        id="title"
                        name="title"
                        type="text"
                        placeholder="Shkruani titullin e fletushkes"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.title || ""}
                      />
                      {validation.touched.title && validation.errors.title ? (
                        <FormFeedback type="invalid" className="d-block">
                          {validation.errors.title}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3 position-relative">
                      <Label htmlFor="task-assign-input">
                        Selektoni datat kur oferta eshte aktive
                      </Label>
                      <InputGroup>
                        <Flatpickr
                          className="form-control d-block"
                          placeholder="Data e fillimit te ofertes"
                          onChange={handleStartDateSelect}
                          value={validation.values.startDate}
                          options={{
                            altInput: true,
                            altFormat: "F j, Y",
                            dateFormat: "Y-m-d",
                          }}
                        />
                        <Flatpickr
                          className="form-control d-block"
                          placeholder="Data e mbarimit te ofertes"
                          onChange={handleEndDateSelect}
                          value={validation.values.endDate}
                          options={{
                            altInput: true,
                            altFormat: "F j, Y",
                            dateFormat: "Y-m-d",
                          }}
                        />

                        {validation.touched.startDate &&
                        validation.errors.startDate ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.startDate}
                          </FormFeedback>
                        ) : null}
                        {validation.touched.endDate &&
                        validation.errors.endDate ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.endDate}
                          </FormFeedback>
                        ) : null}
                      </InputGroup>
                    </div>

                    <div className="mb-3 position-relative">
                      <Label htmlFor="task-assign-input">
                        Selektoni qytetin
                      </Label>

                      <Select
                        value={selectedCity}
                        onChange={handleSelectCity}
                        options={citiesOptions}
                        isMulti={true}
                        className="select2-selection"
                      />

                      {validation.touched.city && validation.errors.city ? (
                        <FormFeedback type="invalid" className="d-block">
                          {validation.errors.city}
                        </FormFeedback>
                      ) : null}
                    </div>
                    {!!selectedCity &&
                      Array.isArray(locationOptions) &&
                      locationOptions?.length > 0 &&
                      handleLocationDropDownShow(locationOptions) && (
                        <div className="mb-3 position-relative">
                          <Label htmlFor="task-assign-input">
                            Selektoni lokacionin
                          </Label>

                          <Select
                            name="location"
                            value={selectedLocations}
                            onChange={handleSelectLocation}
                            options={locationOptions}
                            isMulti={true}
                            className="select2-selection"
                          />

                          {validation.touched.city &&
                          validation.errors.location ? (
                            <FormFeedback type="invalid" className="d-block">
                              {validation.errors.location}
                            </FormFeedback>
                          ) : null}
                        </div>
                      )}

                    <div>
                      <Label>
                        Zgjidhni fotot dhe rendisni ato sipas rendit qe do te
                        shfaqen ne applikacion!
                      </Label>
                      <Dropzone
                        onDrop={acceptedFiles => {
                          handleAcceptedFiles(acceptedFiles)
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone">
                            <div
                              className="dz-message needsclick"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div className="dz-message needsclick">
                                <div className="mb-3">
                                  <i className="display-4 text-muted bx bxs-cloud-upload" />
                                </div>
                                <h4>Drop files here or click to upload.</h4>
                              </div>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                      <div
                        className="dropzone-previews mt-3"
                        id="file-previews"
                      >
                        {loadingImages && (
                          <Spinner color="primary" className="start-50" />
                        )}
                        {selectedFiles.map((file, index) => {
                          return (
                            <BrochureImageCard
                              key={index}
                              file={file}
                              id={file.id}
                              index={index}
                              moveImage={moveImage}
                              selectedFiles={selectedFiles}
                              setSelectedFiles={setSelectedFiles}
                            />
                          )
                        })}
                      </div>

                      {validation.errors.images && validation.touched.images ? (
                        <FormFeedback type="invalid" className="d-block">
                          {validation.errors.images}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col lg={12}>
                <div className="text-end mb-4">
                  <Button type="submit" color="primary">
                    {brochureToEdit ? "Edito fletushken" : "Krijo fletushken"}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(BrochuresCreate)
