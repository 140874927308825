/* PROJECTS */
export const GET_BROCHURES = "GET_BROCHURES"
export const GET_BROCHURES_SUCCESS = "GET_BROCHURES_SUCCESS"
export const GET_BROCHURES_FAIL = "GET_BROCHURES_FAIL"

export const ADD_BROCHURE = "ADD_BROCHURE"
export const ADD_BROCHURE_SUCCESS = "ADD_BROCHURE_SUCCESS"
export const ADD_BROCHURE_FAIL = "ADD_BROCHURE_FAIL"

/**
 * Edit user
 */
export const UPDATE_BROCHURE = "UPDATE_BROCHURE"
export const UPDATE_BROCHURE_SUCCESS = "UPDATE_BROCHURE_SUCCESS"
export const UPDATE_BROCHURE_FAIL = "UPDATE_BROCHURE_FAIL"

/**
 * Delete user
 */
export const DELETE_BROCHURE = "DELETE_BROCHURE"
export const DELETE_BROCHURE_SUCCESS = "DELETE_BROCHURE_SUCCESS"
export const DELETE_BROCHURE_FAIL = "DELETE_BROCHURE_FAIL"

export const SET_BROCHURE_TO_EDIT = "SET_BROCHURE_TO_EDIT"
