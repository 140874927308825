import React, { useEffect, useMemo, useRef, useState } from "react"
import withRouter from "components/Common/withRouter"
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Button,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormFeedback,
  Input,
} from "reactstrap"
import {
  MapContainer,
  TileLayer,
  Marker,
  useMapEvents,
  Popup,
} from "react-leaflet"
import "leaflet/dist/leaflet.css"
//Import Component
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"
// FlatPickr
import "flatpickr/dist/themes/material_blue.css"
import * as Yup from "yup"

//redux
import { useDispatch, useSelector } from "react-redux"
import TableContainer from "components/Common/TableContainer"
import { cityInfos, KsCities } from "helpers/constans"
import { useFormik } from "formik"
import { updateStore } from "store/actions"
import { toast } from "react-toastify"

const customIcon = new L.Icon({
  iconUrl: require("./locationIcon.png"),
  iconSize: [32, 32], // size of the icon
})
const customIcon2 = new L.Icon({
  iconUrl: require("./locationIcon.png"),
  iconSize: [50, 50], // size of the icon
})

const LocationsList = () => {
  //meta title
  document.title = "Fletushkat Aktive"
  const store = useSelector(state => state.Profile.store)

  const [locationsData, setLocationsData] = useState(null)
  const [modal, setModal] = useState(false)
  const [location, setLocation] = useState(null)
  const [cityCenter, setCityCenter] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)
  const draggingMode = useRef(false)
  const [tableMarkersState, setTableMarkersState] = useState([])
  const [markerName, setMarkerName] = useState("")
  const [inputFocused, setInputFocused] = useState(false)
  const dispatch = useDispatch()
  const [showError, setShowError] = useState(false)
  const [hoveredIndex, setHoveredIndex] = useState()

  const isEqual = useMemo(() => {
    const copiedArr = store?.locations?.filter(
      item => item.city === location?.city
    )
    return JSON.stringify(copiedArr) === JSON.stringify(tableMarkersState)
  }, [store, tableMarkersState, location])

  const hasEmptyString = tableMarkersState.find(item => {
    if (
      !item.name ||
      item.name.trim() === "" ||
      item.name.trim().length === 0
    ) {
      return true
    }
  })

  const toggle = () => {
    if (modal) {
      setModal(false)
      setLocation(null)
    } else {
      setModal(true)
    }
  }

  const handleEditCityClick = arg => {
    const cityInfo = cityInfos.find(item => item.city === arg.city)
    setLocation(arg)
    setCityCenter(cityInfo?.center)
    setModal(true)
    if (Array.isArray(store?.locations)) {
      setTableMarkersState(
        store?.locations?.filter(item => item.city === cityInfo.city)
      )
    }
  }
  const onClickDelete = arg => {
    setLocation(arg)
    setDeleteModal(true)
  }

  const handleDeleteOrder = () => {
    if (store.city.includes("all")) {
      const cities = KsCities.filter(item => item !== location.city)
      cities.shift()
      dispatch(
        updateStore({
          id: store.id,
          city: cities,
        })
      )
    } else {
      if (store.city.length > 1) {
        const cities = store.city.filter(item => item !== location.city)
        dispatch(
          updateStore({
            id: store.id,
            city: cities,
          })
        )
      } else {
        toast.error("Duhet te keni se paku nje qytet te regjistruar!")
      }
    }
    setDeleteModal(false)
  }

  useEffect(() => {
    if (store?.city) {
      if (store.city.includes("all")) {
        setLocationsData(cityInfos.map(item => ({ city: item.city })))
      } else {
        setLocationsData(store.city.map(item => ({ city: item })))
      }
    }
  }, [store])

  const validation = useFormik({
    initialValues: {
      title: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Ju lutem vendosni nje titull"),
    }),
  })

  const columns = useMemo(
    () => [
      {
        header: "Qyteti",
        accessorKey: "city",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Numri i lokacioneve",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cellProps => {
          const copiedArr = store?.locations?.filter(
            item => item?.city === cellProps.row.original?.city
          )
          return (
            <>
              <h5 className="text-truncate font-size-14">
                {Array.isArray(copiedArr) ? copiedArr?.length : 0}
              </h5>
            </>
          )
        },
      },
      {
        accessorKey: "edito",
        header: "Edito",
        enableColumnFilter: false,

        cell: cellProps => {
          return (
            <div>
              {cellProps.getValue()}
              <Button
                onClick={e => {
                  e.stopPropagation() // Prevent row click from triggering
                  handleEditCityClick(cellProps.row.original)
                }}
              >
                <span>Edito lokacionet ne hart</span>
              </Button>
            </div>
          )
        },
      },
      {
        accessorKey: "fshije",
        header: "Fshije",
        enableColumnFilter: false,

        cell: cellProps => {
          return (
            <div>
              {cellProps.getValue()}
              <Button
                style={{ backgroundColor: "red", borderWidth: 0 }}
                onClick={e => {
                  e.stopPropagation() // Prevent row click from triggering
                  onClickDelete(cellProps.row.original)
                }}
              >
                <span>Fshij Qytetin</span>
              </Button>
            </div>
          )
        },
      },
    ],
    [store]
  )
  const mapMarkersColumn = useMemo(
    () => [
      {
        header: "Emri i pikes",
        accessorKey: "name",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cellProps => {
          return (
            <Form
              id="marker"
              onSubmit={e => {
                e.preventDefault()
                validation.handleSubmit()
                return false
              }}
            >
              <Input
                id="title"
                name="title"
                type="text"
                placeholder="Shkruaj emrin e pikes..."
                onChange={e => {
                  setMarkerName(e.target.value)
                  setTableMarkersState(prevMarkers =>
                    prevMarkers.map((marker, i) =>
                      i === cellProps.row.index
                        ? {
                            ...marker,
                            name: e.target.value,
                          }
                        : marker
                    )
                  )
                }}
                onFocus={() => {
                  setMarkerName(cellProps.row.original.name)
                  setInputFocused()
                }}
                onBlur={() => setInputFocused(false)}
                value={
                  inputFocused ? markerName : cellProps.row.original.name || ""
                }
              />
              {showError && !!hasEmptyString && !cellProps.row.original.name ? (
                <FormFeedback type="invalid" className="d-block">
                  Ju lutem vendosni emrin e lokacionit
                </FormFeedback>
              ) : null}
            </Form>
          )
        },
      },
      {
        accessorKey: "fshije",
        header: "Fshije",
        enableColumnFilter: false,
        cell: cellProps => {
          return (
            <div>
              {cellProps.getValue()}
              <Button
                style={{ backgroundColor: "red", borderWidth: 0 }}
                onClick={e => {
                  e.stopPropagation() // Prevent row click from triggering
                  setTableMarkersState(prevState => {
                    return prevState.filter(
                      item => item.lat !== cellProps.row.original.lat
                    )
                  })
                }}
              >
                <span>Fshij piken</span>
              </Button>
            </div>
          )
        },
      },
    ],
    [showError]
  )

  const MapClickHandler = () => {
    useMapEvents({
      click(e) {
        // Add the clicked location's coordinates to the markers state
        setTableMarkersState(prevState => [
          ...prevState,
          {
            name: "",
            lat: e.latlng.lat,
            lng: e.latlng.lng,
            city: location?.city,
          },
        ])
      },
    })
    return null // No visual component rendered
  }

  const handleMarkerDrag = (e, index) => {
    const newPosition = e.target.getLatLng() // Get new position
    setTableMarkersState(prevMarkers =>
      prevMarkers.map((marker, i) =>
        i === index
          ? {
              ...marker,
              lat: newPosition.lat,
              lng: newPosition.lng,
              position: [newPosition.lat, newPosition.lng],
            }
          : marker
      )
    )
    console.log(`Marker at index ${index} moved to:`, newPosition)
  }

  const handleChangeLocations = async () => {
    if (!!hasEmptyString) {
      setShowError(true)
      return
    }
    const copiedItems = tableMarkersState.map(item => {
      return {
        ...item,
        city: location?.city,
      }
    })

    try {
      const updatedLocations =
        store?.locations?.filter(item => item.city !== location?.city) || []

      dispatch(
        updateStore({
          id: store.id,
          locations: [...copiedItems, ...updatedLocations],
        })
      )
    } catch (error) {
      console.log(error)
      toast.error("Diqka shkoi keq!")
    }
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
        text={"A jeni i sigurt qe doni te fshini kete qytet?"}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Lokacionet e juaja"
            breadcrumbItem="Lista e lokacioneve"
          />
          {!locationsData ? (
            <Spinner
              color="primary"
              className="position-absolute top-50 start-50"
            />
          ) : (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      columns={columns}
                      data={locationsData}
                      isGlobalFilter={true}
                      isPagination={true}
                      isCustomPageSize={true}
                      SearchPlaceholder="Kerko lokacionin..."
                      buttonClass="btn btn-rounded"
                      tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                      theadClass="table-light"
                      paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                      pagination="pagination"
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          <Modal
            onClosed={() => setModal(false)}
            size="lg"
            isOpen={modal}
            toggle={toggle}
          >
            {!!modal && (
              <>
                <ModalHeader toggle={toggle} tag="h4">
                  Pikat ne {location?.city}
                </ModalHeader>
                <ModalBody>
                  <TableContainer
                    columns={mapMarkersColumn}
                    data={tableMarkersState}
                    isGlobalFilter={true}
                    isPagination={true}
                    onMouseEnter={index => {
                      setHoveredIndex(index)
                    }}
                    isCustomPageSize={true}
                    SearchPlaceholder="Kerko lokacionin..."
                    buttonClass="btn btn-rounded"
                    tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                    theadClass="table-light"
                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                    pagination="pagination"
                  />
                  {cityCenter && (
                    <MapContainer
                      center={cityCenter}
                      zoom={13}
                      style={{ height: "40vh" }}
                    >
                      <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />
                      <MapClickHandler />
                      {tableMarkersState.map((position, idx) => (
                        <Marker
                          draggable
                          icon={hoveredIndex === idx ? customIcon2 : customIcon}
                          key={idx}
                          position={position}
                          eventHandlers={{
                            dragend: e => {
                              draggingMode.current = false
                              handleMarkerDrag(e, idx)
                            },
                          }}
                        >
                          <Popup>
                            Marker {idx + 1}: {position.lat.toFixed(4)},{" "}
                            {position.lng.toFixed(4)}
                          </Popup>
                        </Marker>
                      ))}
                      {/* <Marker position={cityCenter}>
                    <Popup>
                      A pretty CSS3 popup. <br /> Easily customizable.
                    </Popup>
                  </Marker> */}
                    </MapContainer>
                  )}
                </ModalBody>
                <ModalFooter>
                  {!isEqual ? (
                    <Button onClick={handleChangeLocations}>
                      Ruaj ndryshimet
                    </Button>
                  ) : (
                    <Button onClick={toggle}>Kthehu</Button>
                  )}
                </ModalFooter>
              </>
            )}
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(LocationsList)
