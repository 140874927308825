import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, RESET_PASSWORD } from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import { getStore } from "store/actions"
import { toast } from "react-toastify"

const fireBaseBackend = getFirebaseBackend()

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(
      fireBaseBackend.loginUser,
      user.email,
      user.password
    )
    yield put(loginSuccess(response))
    yield put(getStore())
    history("/fletushkat-aktive")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* resetPassword({ payload: { email } }) {
  try {
    yield call(fireBaseBackend.handleForgotPasswordAPI, email)
    toast.success("Ju lutem shikoni email-in!", { autoClose: 2000 })
  } catch (error) {
    toast.error("Email-i nuk eshte valid!", { autoClose: 2000 })
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")

    const response = yield call(fireBaseBackend.logout)
    yield put(logoutUserSuccess(response))
    history("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(RESET_PASSWORD, resetPassword)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
