import firebase from "firebase/compat/app"
import {
  getFirestore,
  getDocs,
  collection,
  getDoc,
  doc,
  setDoc,
  addDoc,
  where,
  query,
  deleteDoc,
  updateDoc,
} from "firebase/firestore"
// Add the Firebase products that you want to use
import "firebase/compat/auth"
import "firebase/compat/firestore"

import { getDownloadURL, ref, uploadBytes, getStorage } from "firebase/storage"
import { toast } from "react-toastify"

import { sendPasswordResetEmail, getAuth } from "firebase/auth"

const collectionsEnum = {
  STORES: "stores",
  CATEGORIES: "categories",
  BROCHURES: "brochures",
}

class FirebaseAuthBackend {
  constructor(firebaseConfig) {
    if (firebaseConfig) {
      // Initialize Firebase
      this.app = firebase.initializeApp(firebaseConfig)
      this.db = getFirestore(this.app)
      this.auth = getAuth(this.app)

      firebase.auth().onAuthStateChanged(user => {
        if (user) {
          localStorage.setItem("authUser", JSON.stringify(user))
        } else {
          localStorage.removeItem("authUser")
        }
      })
    }
  }

  /**
   * Registers the user with given details
   */
  editProfileAPI = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(
          user => {
            resolve(firebase.auth().currentUser)
          },
          error => {
            reject(this._handleError(error))
          }
        )
    })
  }

  uploadMultipleImages = async (files, endDate) => {
    try {
      const storage = getStorage()

      // Prepare an array of promises for uploading and retrieving URLs
      const uploadPromises = files.map(async file => {
        // Create a storage reference for each file
        const storageRef = ref(
          storage,
          `brochure-images/${endDate}/${Date.now()}_${file.name}`
        )

        // Upload the file and chain the promise to get its download URL
        await uploadBytes(storageRef, file)
        return await getDownloadURL(storageRef)
      })

      // Resolve all upload promises
      const downloadURLs = await Promise.all(uploadPromises)

      console.log("All files uploaded successfully:", downloadURLs)

      // Return the array of URLs if needed
      return downloadURLs
    } catch (error) {
      console.error("Error uploading images:", error)
      throw error
    }
  }

  uploadStoreImage = async (file, name) => {
    try {
      const storage = getStorage()
      const storageRef = ref(storage, `store_logos/${name}`)
      await uploadBytes(storageRef, file)
      const downloadURL = await getDownloadURL(storageRef)
      return downloadURL
    } catch (error) {
      toast.error("Gabim gjate ngarkimit te fotos!")
      throw error
    }
  }

  /**
   * Login user with given details
   */
  loginUser = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(
          user => {
            localStorage.setItem(
              "authUser",
              JSON.stringify(firebase.auth().currentUser)
            )

            resolve(firebase.auth().currentUser)
          },
          error => {
            reject(this._handleError(error))
          }
        )
    })
  }

  /**
   * forget Password user with given details
   */
  forgetPassword = email => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .sendPasswordResetEmail(email, {
          url:
            window.location.protocol + "//" + window.location.host + "/login",
        })
        .then(() => {
          resolve(true)
        })
        .catch(error => {
          reject(this._handleError(error))
        })
    })
  }

  /**
   * Logout the user
   */
  logout = () => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signOut()
        .then(() => {
          resolve(true)
        })
        .catch(error => {
          reject(this._handleError(error))
        })
    })
  }

  setLoggeedInUser = user => {
    localStorage.setItem("authUser", JSON.stringify(user))
  }

  /**
   * Returns the authenticated user
   */
  getAuthenticatedUser = () => {
    if (!localStorage.getItem("authUser")) return null
    return JSON.parse(localStorage.getItem("authUser"))
  }

  /**
   * Handle the error
   * @param {*} error
   */
  _handleError(error) {
    // var errorCode = error.code;
    var errorMessage = error.message
    return errorMessage
  }

  getStoreAPI = async storeId => {
    try {
      const docRef = doc(this.db, collectionsEnum.STORES, storeId)
      const docSnap = await getDoc(docRef)
      if (docSnap.exists()) {
        return { ...docSnap.data(), id: docSnap.id }
      } else {
        return null
      }
    } catch (err) {
      throw err
    }
  }

  addStoreAPI = async (docId, data) => {
    try {
      const document = await setDoc(
        doc(this.db, collectionsEnum.STORES, docId),
        data
      )
      return document
    } catch (error) {
      console.error("Error adding document: ", error)
      throw error
    }
  }

  updateStoreAPI = async (docId, updatedData) => {
    try {
      const docRef = doc(this.db, collectionsEnum.STORES, docId)

      const document = await updateDoc(docRef, updatedData)
      return document
    } catch (error) {
      throw error
    }
  }

  updateBrochure = async (docId, updatedData) => {
    try {
      const docRef = doc(this.db, collectionsEnum.BROCHURES, docId)

      const document = await updateDoc(docRef, updatedData)
      return document
    } catch (error) {
      console.error("Error updating document:", error)
      throw error
    }
  }

  deleteBrochureAPI = async docId => {
    try {
      const document = await deleteDoc(
        doc(this.db, collectionsEnum.BROCHURES, docId)
      )
      return document
    } catch (error) {
      console.error("Error adding document: ", error)
      throw error
    }
  }

  addBrochureAPI = async data => {
    try {
      const document = await addDoc(
        collection(this.db, collectionsEnum.BROCHURES),
        data
      )
      return document
    } catch (error) {
      console.error("Error adding document: ", error)
      throw error
    }
  }

  getBrochuresAPI = async storeId => {
    try {
      const collectionRef = collection(this.db, collectionsEnum.BROCHURES)

      const q = query(collectionRef, where("storeId", "==", storeId))

      const querySnapshot = await getDocs(q)

      const documents = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }))
      return documents
    } catch (err) {
      throw err
    }
  }

  handleForgotPasswordAPI = async email => {
    try {
      await sendPasswordResetEmail(this.auth, email)
      return true
    } catch (error) {
      console.error("Error sending password reset email:", error)
      throw error
    }
  }

  getCategoriesAPI = async () => {
    try {
      const querySnapshot = await getDocs(
        collection(this.db, collectionsEnum.CATEGORIES)
      ) // Replace with your collection name
      const docs = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }))
      return docs
    } catch (err) {
      throw err
    }
  }
}

let _fireBaseBackend = null

/**
 * Initilize the backend
 * @param {*} config
 */
const initFirebaseBackend = config => {
  if (!_fireBaseBackend) {
    _fireBaseBackend = new FirebaseAuthBackend(config)
  }
  return _fireBaseBackend
}

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
  return _fireBaseBackend
}

export { initFirebaseBackend, getFirebaseBackend }
